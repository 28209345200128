async function importFlickity() {
    const { default: Flickity } = await import(/* webpackChunkName: "flickity" */ 'flickity');
    const { default: FlickityFade } = await import(/* webpackChunkName: "flickity-fade" */ 'flickity-fade');

    return Flickity;
}

importFlickity().then((Flickity) => {

    const hero = document.querySelector('.home-hero');
    const heroSlider = new Flickity(hero, {
        fade: true,
        autoPlay: 4000,
        adaptiveHeight: true,
        draggable: true,
        wrapAround: true,
        prevNextButtons: true,
        imagesLoaded: true,
        pageDots: true,
        accessibility: false,
        percentPosition: true,
        resize: true,
        setGallerySize: true
    });



});